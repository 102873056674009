import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ConfirmDialog from '../ConfirmDialog';
import AddDialog from './AddDialog';
import Title from './Title';
import { Link, Navigate } from 'react-router-dom';
import { getToken, getRoleFromToken, getDeptFromRole, getDecodedToken } from '../auth-utils';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  setUsersDept,
  closeAddUserDialog,
  closeDeleteUserDialog,
  closeDisableCARDialog,
  closeDisableUserDialog,
  closeEnableCARDialog,
  closeEnableUserDialog,
  closeGrantAdminDialog,
  closeRevokeAdminDialog,
  disableCARNotification,
  disableUser,
  deleteUser,
  enableCARNotification,
  enableUser,
  getAllPortalUsersFromDept,
  getAllPortalUsers,
  getAllRecipients,
  grantAdmin,
  revokeAdmin,
  snackBarClosed,
  resetDeptAdmin,
  deleteRecipient,
  getAllPortalRecipientsFromDept,
} from './DeptUserAdminSlice';
import TableActions from './TableActions';
import UserTable from './UserTable';
import RecipientTable from './RecipientTable';
import RecipientTableActions from './RecipientsTableActions';

interface TableProps {
  deptName: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Admin = (props: TableProps) => {
  const usersDeptOrSupersSelectedDept: string = useAppSelector(
    (state) => state.departmentAdmin.usersDeptOrSupersSelectedDept
  );

  const showAddDialog = useAppSelector((state) => state.departmentAdmin.showAddUserDialog);
  const showDeleteDialog = useAppSelector((state) => state.departmentAdmin.showDeleteUsersDialog);
  const showEnableUsersDialog = useAppSelector((state) => state.departmentAdmin.showEnableUsersDialog);
  const showDisableUsersDialog = useAppSelector((state) => state.departmentAdmin.showDisableUsersDialog);
  const showGrantAdminDialog = useAppSelector((state) => state.departmentAdmin.showGrantAdminDialog);
  const showRevokeAdminDialog = useAppSelector((state) => state.departmentAdmin.showRevokeAdminDialog);
  const showEnableCARDialog = useAppSelector((state) => state.departmentAdmin.showEnableCARDialog);
  const showDisableCARDialog = useAppSelector((state) => state.departmentAdmin.showDisableCARDialog);
  const snackbarOpen = useAppSelector((state) => state.departmentAdmin.snackbarOpen);
  const errorMessage = useAppSelector((state) => state.departmentAdmin.errorMessage);
  const selectedUsersEmails = useAppSelector((state) => state.departmentAdmin.selectedUserEmails);
  const uniqueEmailDomains = useAppSelector((state) => state.departmentAdmin.uniqueEmailDomains);
  const recipientUniqueEmailDomains = useAppSelector((state) => state.departmentAdmin.recipientUniqueEmailDomains);
  const ncscSuper = useAppSelector((state) => state.departmentAdmin.ncscSuper);
  const addType = useAppSelector((state) => state.departmentAdmin.addType);
  const showDeleteRecipientDialog = useAppSelector((state) => state.departmentAdmin.showDeleteRecipientDialog);
  const selectedRecipientEmails = useAppSelector((state) => state.departmentAdmin.selectedRecipientEmails);

  let token: string = '';
  try {
    token = getToken();
  } catch {
    console.error('failed to find token');
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      dispatch(setUsersDept(props.deptName));
      const isNCSCSuper = getDeptFromRole(getRoleFromToken(getDecodedToken())) === 'NCSCSUPER';
      if (isNCSCSuper) {
        dispatch(getAllPortalUsers(token));
        dispatch(getAllRecipients(token));
      } else {
        dispatch(getAllPortalUsersFromDept(token));
        dispatch(getAllPortalRecipientsFromDept(token));
      }
    } catch (e) {
      console.log(`error: ${e}`);
    }
  }, []);

  if (!props.deptName) {
    return <Navigate replace to={'/'} />;
  }
  return !props.deptName ? (
    <Navigate replace to={'/'} />
  ) : (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '30px',
        }}
      >
        <Button
          onClick={() => {
            dispatch(resetDeptAdmin());
          }}
          component={Link}
          to="/"
          id="btn-adm-back"
          data-testid="back-button"
          variant="contained"
          sx={{ marginRight: '1.75vh' }}
        >
          Back
        </Button>
      </div>
      <div
        style={{
          backgroundColor: '#051c48',
          textAlign: 'center',
        }}
      >
        <ConfirmDialog
          open={showDeleteDialog}
          title="Are you sure you want to delete the following user(s)?"
          arialabelledby="Confirm deletion choices dialogue"
          ariadescribe="List email addresses of users to be deleted and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Delete"
          cancelCallback={() => dispatch(closeDeleteUserDialog())}
          affirmativeCallback={() => {
            dispatch(
              deleteUser({
                token: getToken(),
                usersByEmail: selectedUsersEmails,
                userdept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />

        <AddDialog
          usersDeptOrSupersSelectedDept={usersDeptOrSupersSelectedDept}
          open={showAddDialog}
          closedCallback={() => dispatch(closeAddUserDialog())}
          uniqueEmailDomains={uniqueEmailDomains}
          recipientUniqueEmailDomains={recipientUniqueEmailDomains}
          userIsNCSCSuper={ncscSuper}
          addType={addType}
        />

        <ConfirmDialog
          open={showEnableUsersDialog}
          title="Are you sure you want to enable the following user(s)?"
          arialabelledby="Confirm enable users dialogue"
          ariadescribe="Lists users to be enabled and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Enable"
          cancelCallback={() => dispatch(closeEnableUserDialog())}
          affirmativeCallback={async () => {
            dispatch(
              enableUser({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />

        <ConfirmDialog
          open={showDisableUsersDialog}
          title="Are you sure you want to disable the following user(s)?"
          arialabelledby="Confirm disable users dialogue"
          ariadescribe="Lists users to be disabled and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Disable"
          cancelCallback={() => dispatch(closeDisableUserDialog())}
          affirmativeCallback={() => {
            dispatch(
              disableUser({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />
        <ConfirmDialog
          open={showGrantAdminDialog}
          title="Are you sure you want to grant admin privileges to these users?"
          arialabelledby="Confirm users to be granted admin privileges dialogue"
          ariadescribe="Lists user to be granted admin privileges and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Grant Admin"
          cancelCallback={() => dispatch(closeGrantAdminDialog())}
          affirmativeCallback={() => {
            dispatch(
              grantAdmin({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />
        <ConfirmDialog
          open={showRevokeAdminDialog}
          title="Are you sure you want to revoke admin privileges from these users?"
          arialabelledby="Confirm to revoke users admin privileges dialogue"
          ariadescribe="Lists user to have their admin privileges revoked and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Revoke Admin"
          cancelCallback={() => dispatch(closeRevokeAdminDialog())}
          affirmativeCallback={() => {
            dispatch(
              revokeAdmin({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />
        <ConfirmDialog
          open={showEnableCARDialog}
          title="Are you sure you want these users to receive CAR notification emails?"
          arialabelledby="Confirm users to have CAR notifications enabled dialogue"
          ariadescribe="Lists users to have their receive CAR notification status set to enabled and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Enable"
          cancelCallback={() => dispatch(closeEnableCARDialog())}
          affirmativeCallback={() => {
            dispatch(
              enableCARNotification({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />
        <ConfirmDialog
          open={showDisableCARDialog}
          title="Are you sure you want these users to stop receiving CAR notification emails?"
          arialabelledby="Confirm users to have CAR notification enabled/diabled dialogue"
          ariadescribe="Lists users to have their receive CAR notification status set to disabled and asks for confirmation"
          bodyText={selectedUsersEmails}
          affirmativeText="Disable"
          cancelCallback={() => dispatch(closeDisableCARDialog())}
          affirmativeCallback={() => {
            dispatch(
              disableCARNotification({
                token: getToken(),
                users: selectedUsersEmails,
                dept: usersDeptOrSupersSelectedDept,
              })
            );
          }}
        />
        <ConfirmDialog
          open={showDeleteRecipientDialog}
          title="Are you sure you want these users to stop receiving CAR notification emails?"
          arialabelledby="Confirm users to to be delete from the CAR notifications table"
          ariadescribe="Lists users to be added to the recieve CAR notification table and asks for confirmation"
          bodyText={selectedRecipientEmails.map((rec) => rec.email)}
          affirmativeText="Delete"
          cancelCallback={() => dispatch(closeDisableCARDialog())}
          affirmativeCallback={() => {
            dispatch(
              deleteRecipient({
                token: getToken(),
                recipientsToDelete: selectedRecipientEmails,
              })
            );
          }}
        />

        {/*###### Tables Grid Container ######*/}
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Title title="Users" />
          </Grid>
          <Grid item xs={12}>
            <TableActions />
            <UserTable />
          </Grid>
          <Grid item xs={12}>
            <Title title="Recipients" />
          </Grid>
          <Grid item xs={12}>
            <RecipientTableActions />
            <RecipientTable />
          </Grid>
        </Grid>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => dispatch(snackBarClosed())}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default Admin;
