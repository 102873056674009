import React from 'react';
import { styled } from '@mui/material/styles';

const Banner = styled('div')({
  letterSpacing: '0.05em',
  margin: '0px',
  padding: '2px 0px',
  wordSpacing: '0.1em',
  backgroundColor: 'CornflowerBlue',
  paddingTop: '4px',
  paddingBottom: '4px',
  color: 'white',
  textAlign: 'center',
  fontSize: '13px',
  fontWeight: 'bold',
  left: '0px',
  width: '100%',
  position: 'absolute',
});

const ClassificationBanner = () => {
  return <Banner>UK OFFICIAL SENSITIVE</Banner>;
};

export default ClassificationBanner;
