import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import AnnouncementList from './Announcements/AnnouncementList';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

type Props = {
  portalURL: string;
  dept: string;
  loggedIn: boolean;
  isAdmin: boolean;
};

const Home = (props: Props) => {
  let deptOrPrivate: string;
  if (props.loggedIn) {
    deptOrPrivate = 'private';
    if (props.dept && props.dept !== 'NCSCSUPER') {
      deptOrPrivate = props.dept; // Empty string = Global tenant, 'private' = Private tenant
    }
  }
  return props.loggedIn ? (
    <>
      <Button
        aria-label="Open HBC Portal Button"
        data-testid="go-to-portal-button"
        variant="contained"
        sx={{ margin: '20px' }}
        onClick={(e) => {
          e.preventDefault();
          const parentDomain = location.hostname.split('.').slice(1).join('.');
          // This cookie is used by the passthrough to set the tenant after a cognito login.
          Cookies.set('es_tenant', deptOrPrivate, { expires: 1, path: '/', domain: parentDomain, secure: true });
          window.location.href = props.portalURL;
        }}
      >
        Go to Portal
      </Button>
      {props.isAdmin ? (
        <>
          <Button
            component={Link}
            to={'/department-admin'}
            aria-label="Open Team Admin for HBC Portal Button"
            data-testid="team-admin-button"
            id="admin-button"
            variant="contained"
            sx={{ margin: '20px' }}
          >
            Team Admin
          </Button>
          {props.dept && props.dept === 'NCSCSUPER' ? (
            <Button
              component={Link}
              to="/announcements-admin"
              aria-label="Open Announcements Admin for HBC Portal Button"
              data-testid="announcement-admin-button"
              id="announcement-button"
              variant="contained"
              sx={{ margin: '20px' }}
            >
              Announcements Admin
            </Button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <span />
      )}
      <AnnouncementList />
    </>
  ) : (
    <>
      <Button
        aria-label="Login to HBC Portal Button"
        data-testid="login-button"
        variant="contained"
        sx={{ margin: '20px' }}
        onClick={() => Auth.federatedSignIn()}
      >
        Login to HBC Portal
        <LoginRoundedIcon />
      </Button>
    </>
  );
};

export default Home;
