import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styles from './Announcements.module.css';

interface props {
  body: string;
  pinned: boolean;
}

const Announcement = (props: props) => {
  const styling = props.pinned
    ? { margin: '10px', background: 'rgb(217 160 84)', color: 'black' }
    : { margin: '10px', background: 'rgb(43 66 135)', color: 'white' };

  return (
    <Card style={styling}>
      <CardContent>
        <div data-testid="announcement">
          <ReactMarkdown className={styles.body}>{props.body}</ReactMarkdown>
        </div>
      </CardContent>
    </Card>
  );
};

export default Announcement;
