import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LandingPage from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import ErrorPage from './Error';
import Config from './env-config';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ClassificationBanner from './ClassificationBanner';
import ServiceStatus from './ServiceStatus';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

Config()
  .then((config) => {
    Amplify.configure(config.amplifyConfig);

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={darkTheme}>
            <ClassificationBanner />
            <BrowserRouter>
              <LandingPage portalURL={config.portalURL} />
            </BrowserRouter>
            <ServiceStatus />
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    );
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  })
  .catch((err: Error) => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
      <React.StrictMode>
        <ErrorPage errorMessage={err.message} />
      </React.StrictMode>
    );
  });
