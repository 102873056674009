import { useAppDispatch, useAppSelector } from '../hooks';
import { openAddRecipientDialog, openDeleteRecipientDialog } from './DeptUserAdminSlice';
import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';

const RecipientTableActions = () => {
  const rowsSelected = useAppSelector((state) => state.departmentAdmin.recipientRowsSelected);
  const dispatch = useAppDispatch();

  return (
    <Grid item xs={12}>
      <ButtonGroup variant="outlined" sx={{ margin: '0.75vh', marginTop: '2vh' }}>
        <Tooltip title="Add Recipient">
          <IconButton
            id="btn-adm-add-recip"
            data-testid="openAddRecDialog"
            sx={{ margin: '0.8vh' }}
            onClick={() => {
              dispatch(openAddRecipientDialog());
            }}
          >
            <PersonAddAlt1RoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Recipient">
          <IconButton
            id="btn-adm-delete-recip"
            data-testid="openDeleteRecDialog"
            sx={{ margin: '0.8vh' }}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openDeleteRecipientDialog());
            }}
          >
            <PersonRemoveRoundedIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Grid>
  );
};

export default RecipientTableActions;
