import React from 'react';
import PropTypes from 'prop-types';

import './App.css';

interface Props {
  errorMessage: string;
}

const ErrorPage: React.FC<Props> = (props) => {
  return (
    <div className="App">
      <div>Error starting HBC Portal Admin App - {props.errorMessage}</div>
      <div>Please contact support</div>
    </div>
  );
};

ErrorPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorPage;
