import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DialogContentText } from '@mui/material';
import { addUser, addRecipient } from './DeptUserAdminSlice';
import { getToken } from '../auth-utils';
import { useAppDispatch } from '../hooks';

interface Props {
  usersDeptOrSupersSelectedDept: string;
  open: boolean;
  closedCallback(): void;
  uniqueEmailDomains: string[];
  recipientUniqueEmailDomains: string[];
  userIsNCSCSuper: boolean;
  addType: AddType | undefined;
}

export enum AddType {
  User,
  Recipient,
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const AddDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const [admin, setAdmin] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');

  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidSurname, setInvalidSurname] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [openEmailWarning, setOpenEmailWarning] = useState(false);
  const [NCSCSuperAddWarning, setNCSCSuperAddWarning] = useState(false);

  let emailRequiresConfirmation = true;

  const userConfirmsInput = () => {
    emailRequiresConfirmation = false;
    setOpenEmailWarning(false);
    setNCSCSuperAddWarning(false);
  };

  const validate = () => {
    let inputIssues = [];

    if (props.addType === AddType.User && firstName.length < 2) {
      setInvalidFirstName(true);
      inputIssues.push('FIRSTNAME');
    } else {
      setInvalidFirstName(false);
    }

    if (props.addType === AddType.User && surname.length < 1) {
      setInvalidSurname(true);
      inputIssues.push('LASTNAME');
    } else {
      setInvalidSurname(false);
    }

    // eslint-disable-next-line
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setInvalidEmail(true);
      inputIssues.push('EMAIL');
    } else {
      setInvalidEmail(false);
    }

    return inputIssues;
  };

  const sendAddUser = () => {
    const inputIssues = validate();

    if (emailRequiresConfirmation && !inputIssues.includes('EMAIL')) {
      props.uniqueEmailDomains.forEach((domain) => {
        if (domain === email.toLowerCase().split('@', 2)[1]) {
          emailRequiresConfirmation = false;
        }
      });
    }
    if (!emailRequiresConfirmation) {
      dispatch(
        addUser({
          token: getToken(),
          admin: admin,
          firstname: firstName,
          surname: surname,
          email: email,
          dept: props.usersDeptOrSupersSelectedDept,
        })
      );
      emailRequiresConfirmation = true;
      setOpenEmailWarning(false);
      closeAndReset();
    } else if (props.userIsNCSCSuper) {
      setNCSCSuperAddWarning(true);
    } else if (emailRequiresConfirmation) {
      setOpenEmailWarning(true);
    }
  };

  const sendAddRecipient = () => {
    const inputIssues = validate();

    const checkIfEmailDomainMatchesExitingUserOrRecipientDomain = (arrayOfDomains: string[], email: string) => {
      arrayOfDomains.forEach((domain) => {
        if (domain === email.toLowerCase().split('@', 2)[1]) {
          emailRequiresConfirmation = false;
        }
      });
    };

    if (emailRequiresConfirmation && !inputIssues.includes('EMAIL')) {
      checkIfEmailDomainMatchesExitingUserOrRecipientDomain(props.uniqueEmailDomains, email);
      checkIfEmailDomainMatchesExitingUserOrRecipientDomain(props.recipientUniqueEmailDomains, email);
    }

    if (!emailRequiresConfirmation) {
      dispatch(
        addRecipient({
          token: getToken(),
          firstName: firstName,
          surname: surname,
          email: email,
          dept: props.usersDeptOrSupersSelectedDept,
        })
      );
      props.closedCallback();
      closeAndReset();
    } else if (props.userIsNCSCSuper) {
      setNCSCSuperAddWarning(true);
    } else if (emailRequiresConfirmation) {
      setOpenEmailWarning(true);
    }
  };

  let ValidateAndSend = () => console.log('Error: send function not set');

  if (props.addType === AddType.User) {
    ValidateAndSend = sendAddUser;
  } else if (props.addType === AddType.Recipient) {
    ValidateAndSend = sendAddRecipient;
  }

  const closeAndReset = () => {
    props.closedCallback();
    setAdmin(false);
    setFirstName('');
    setSurname('');
    setEmail('');
    setInvalidEmail(false);
    setInvalidFirstName(false);
    setInvalidSurname(false);
    emailRequiresConfirmation = true;
    setOpenEmailWarning(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <Dialog
          open={props.open}
          onClose={closeAndReset}
          data-testid="addDialog"
          PaperProps={{
            style: {
              backgroundColor: '#051c48',
            },
          }}
        >
          <DialogTitle>{`Add New ${AddType[props.addType!]} To ${props.usersDeptOrSupersSelectedDept}`}</DialogTitle>
          {props.addType === AddType.Recipient && (
            <DialogTitle variant="subtitle2">Name fields are optional for recipients</DialogTitle>
          )}
          <DialogContent>
            {props.addType === AddType.User && (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox data-testid="admin-checkbox" />}
                  onChange={() => setAdmin(!admin)}
                  label="Admin"
                />
              </FormGroup>
            )}
            <TextField
              error={invalidFirstName}
              autoFocus
              margin="dense"
              inputProps={{ 'data-testid': 'firstname-textfield' }}
              id="dia-admin-add-FirstName"
              label="First Name"
              type="text"
              variant="standard"
              key="1"
              value={firstName}
              onChange={(text) => setFirstName(text.target.value)}
            />
            <br />
            <TextField
              error={invalidSurname}
              margin="dense"
              inputProps={{ 'data-testid': 'lastname-textfield' }}
              id="dia-admin-add-Surname"
              label="Last Name"
              type="text"
              variant="standard"
              key="2"
              value={surname}
              onChange={(text) => setSurname(text.target.value)}
            />
            <br />
            <TextField
              error={invalidEmail}
              helperText="a valid email address"
              margin="dense"
              id="dia-admin-add-email"
              inputProps={{ 'data-testid': 'emailTextField' }}
              label="Email"
              type="email"
              variant="standard"
              key="3"
              value={email}
              onChange={(text) => setEmail(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeAndReset()}>Cancel</Button>
            <Button variant="contained" onClick={() => ValidateAndSend()} data-testid="addUser">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          data-testid="emailWarning"
          open={openEmailWarning}
          PaperProps={{
            style: {
              backgroundColor: '#051c48',
            },
          }}
        >
          <DialogTitle>CAUTION</DialogTitle>
          <DialogContentText sx={{ marginLeft: '15px' }}>
            This user&apos;s email domain does not match with any listed here. Are you sure you want to add this user?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEmailWarning(false);
              }}
            >
              No
            </Button>
            <Button
              data-testid="confirmEmail"
              variant="contained"
              onClick={() => {
                userConfirmsInput();
                ValidateAndSend();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          data-testid="NCSCSuperAddUserWarning"
          open={NCSCSuperAddWarning}
          PaperProps={{
            style: {
              backgroundColor: '#051c48',
            },
          }}
        >
          <DialogTitle>CAUTION</DialogTitle>
          <DialogContentText sx={{ marginLeft: '15px' }}>
            {`Are you sure you want to add user with email: ${email} to department: ${props.usersDeptOrSupersSelectedDept}?`}
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setNCSCSuperAddWarning(false)}>No</Button>
            <Button
              data-testid="NCSCSuperConfrimAdd"
              variant="contained"
              onClick={() => {
                userConfirmsInput();
                ValidateAndSend();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default AddDialog;
