import React from 'react';
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';
import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded';
import { red, green, orange } from '@mui/material/colors';

const DISABLED_REASONS: Record<string, string> = {
  NEVER_USED: 'Unused',
  INACTIVITY: 'Inactivity',
  USER_CONFIGURATION_ERROR: 'User Configuration Error',
  DISABLED_BY_ADMIN: 'Disabled by Admin',
  BOUNCE_EMAIL: 'Email Bounced',
  COMPLAINT_EMAIL: 'Complaint Email Response',
};

interface Props {
  disabled: string;
  disabledReason: string;
  lastSuccessfulLogin: string;
  recipient?: boolean;
}

// If recipient is set to true, we don't care about last successful login as recipients can't login.
const DisabledCell = (props: Props) => {
  if (!props.disabled) {
    if (!props.recipient && !props.lastSuccessfulLogin) {
      return (
        <span
          data-testid="account-enabled-never-used"
          style={{ alignItems: 'center', display: 'flex' }}
          title="Account is enabled but has never used their account."
        >
          <GppMaybeRoundedIcon sx={{ color: orange['500'] }} />
          <span style={{ marginLeft: '8px' }}>Yet to login</span>
        </span>
      );
    } else {
      return (
        <span data-testid="account-enabled" title={`Account is enabled and has been used.`}>
          <GppGoodRoundedIcon sx={{ color: green['500'] }} />
        </span>
      );
    }
  }
  return (
    <span
      data-testid="account-disabled"
      style={{ alignItems: 'center', display: 'flex' }}
      title="Account has been disabled. To re-instate access please either enable the account or contact support (host@ncsc.gov.uk)."
    >
      <GppBadRoundedIcon sx={{ color: red['500'] }} />
      <span style={{ marginLeft: '8px' }}>Disabled ({DISABLED_REASONS[props.disabledReason]})</span>
    </span>
  );
};

export default DisabledCell;
