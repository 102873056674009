import React, { ChangeEvent, useEffect } from 'react';
import styles from '../Announcements/Announcements.module.css';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  SxProps,
} from '@mui/material';
import AdminAnnouncement from './AdminAnnouncement';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import MDEditor from '@uiw/react-md-editor';
import dayjs, { Dayjs } from 'dayjs';
import {
  closeConfirmDialog,
  closeErrorDialog,
  closeFormDialog,
  displayUntilDateChange,
  mdEditorChange,
  openConfirmDialog,
  pinnedChange,
  openFormDialog,
  getAllAnnouncementsFromAPI,
  sendNewAnnouncementToAPI,
  sendUpdatedAnnouncementToAPI,
} from './adminAnnouncementsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getToken } from '../auth-utils';

export type AnnouncementEntry = {
  id: string;
  body: string;
  pinned: boolean;
  displayUntilDate?: string | undefined;
  createdDate: string | undefined;
};

const confirmButtonSX: SxProps = {
  color: 'black',
  background: 'rgba(40, 210, 40, 0.72)',
  border: '1px solid black',
  '&:hover': { background: 'rgb(90,210,90)' },
};

//Need to refactor to useReducer?
const AdminAnnouncementList = () => {
  const announcementData = useAppSelector((state) => state.adminAnnouncements.announcementData);
  const loading = useAppSelector((state) => state.adminAnnouncements.loading);
  const openDialogue = useAppSelector((state) => state.adminAnnouncements.openFormDialog);
  const openConfirmPopup = useAppSelector((state) => state.adminAnnouncements.openConfirmDialog);
  const editMode = useAppSelector((state) => state.adminAnnouncements.editMode);
  const openErrorMessage = useAppSelector((state) => state.adminAnnouncements.openErrorDialog);
  const latestError = useAppSelector((state) => state.adminAnnouncements.latestError);
  const announcementID = useAppSelector((state) => state.adminAnnouncements.announcementID);
  const announcementBody = useAppSelector((state) => state.adminAnnouncements.announcementBody);
  const displayUntilDate = useAppSelector((state) => state.adminAnnouncements.announcementDisplayUntilDate);
  const pinned = useAppSelector((state) => state.adminAnnouncements.announcementPinned);
  const dispatch = useAppDispatch();
  const token = getToken();
  useEffect(() => {
    dispatch(getAllAnnouncementsFromAPI(token));
  }, []);

  const handleDatePickerChange = (value: Dayjs | null | undefined) => {
    if (!value) dispatch(displayUntilDateChange(undefined));
    else dispatch(displayUntilDateChange(value.toISOString()));
  };

  return (
    <>
      <Button data-testid="new-announcement-button" onClick={() => dispatch(openFormDialog())}>
        New Announcement
      </Button>
      <Dialog
        open={openDialogue}
        onClose={() => {
          dispatch(closeFormDialog());
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '800px',
            background: 'rgb(43, 66, 135)',
            color: 'white',
            border: '2px solid black',
          },
        }}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <FormControlLabel
              label="Display Until Date (Leave blank to display forever)"
              labelPlacement="end"
              slotProps={{
                typography: { sx: { marginLeft: '15px' } },
              }}
              control={
                <DatePicker
                  disablePast
                  value={displayUntilDate ? dayjs(displayUntilDate) : undefined}
                  onChange={handleDatePickerChange}
                  data-testid="date-picker"
                  slotProps={{
                    popper: { sx: { '& .MuiPaper-root': { color: 'white', background: 'rgb(20,20,20)' } } },
                  }}
                />
              }
            />
          </LocalizationProvider>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="checkbox"
                  checked={pinned}
                  color="primary"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => dispatch(pinnedChange(event))}
                  inputProps={{ 'aria-label': 'Pin this announcement checkbox' }}
                />
              }
              label="Pin this announcement (Makes it appear at the top in yellow)"
              labelPlacement="end"
            />
          </FormGroup>
          <MDEditor
            data-testid="MDEditor"
            height={'400px'}
            value={announcementBody}
            onChange={(value: string | undefined) => dispatch(mdEditorChange(value))}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="close-button" onClick={() => dispatch(closeFormDialog())}>
            Cancel
          </Button>
          <Button data-testid="confirm-button" onClick={() => dispatch(openConfirmDialog())}>
            {editMode ? 'Apply Edit' : 'Create Announcement'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmPopup}
        onClose={() => dispatch(closeConfirmDialog())}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '800px',
            background: 'rgb(43, 66, 135)',
            color: 'white',
            border: '1px solid black',
          },
        }}
      >
        <DialogTitle data-testid="announcements-admin-dialog-title">
          Are you sure you want to {editMode ? 'edit' : 'create'} this announcement?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => dispatch(closeConfirmDialog())}>No, take me back</Button>
          {editMode ? (
            <Button
              onClick={() => {
                dispatch(
                  sendUpdatedAnnouncementToAPI({
                    token: token,
                    id: announcementID,
                    body: announcementBody,
                    displayUntilDate: displayUntilDate,
                    pinned: pinned,
                  })
                );
              }}
              sx={confirmButtonSX}
            >
              Apply Edit
            </Button>
          ) : (
            <Button
              onClick={() => {
                dispatch(
                  sendNewAnnouncementToAPI({
                    token: token,
                    body: announcementBody,
                    displayUntilDate: displayUntilDate,
                    pinned: pinned,
                  })
                );
              }}
              sx={confirmButtonSX}
            >
              Add New Announcement
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openErrorMessage}
        onClose={() => dispatch(closeErrorDialog())}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '800px',
            background: 'rgb(43, 66, 135)',
            color: 'white',
            border: '1px solid black',
          },
        }}
      >
        <DialogTitle data-testid="announcements-admin-error-dialog-title">
          An error has occured: {latestError}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => dispatch(closeErrorDialog())}>Close</Button>
        </DialogActions>
      </Dialog>
      <div className={loading ? styles.loadingContainer : styles.container}>
        {loading ? (
          <CircularProgress className={styles.loading} aria-label={'Announcements loading'} />
        ) : (
          announcementData.map((announcement: AnnouncementEntry, index) => (
            <AdminAnnouncement
              key={index}
              id={announcement.id}
              body={announcement.body}
              pinned={announcement.pinned}
              displayUntilDate={announcement.displayUntilDate}
              aria-label="Announcement"
            />
          ))
        )}
      </div>
    </>
  );
};

export default AdminAnnouncementList;
