import { Button } from '@mui/material';
import React from 'react';
import AdminAnnouncementsList from './AdminAnnouncementsList';
import { Link, Navigate } from 'react-router-dom';

type Props = {
  deptName: string;
};

const AnnouncementsAdmin = (props: Props) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '30px',
        }}
      >
        <Button component={Link} to="/" id="btn-adm-back" variant="contained" sx={{ marginRight: '1.75vh' }}>
          Back
        </Button>
      </div>
      {props.deptName === 'NCSCSUPER' ? <AdminAnnouncementsList /> : <Navigate replace to={'/'} />}
    </>
  );
};

export default AnnouncementsAdmin;
