import axios from 'axios';
import Config from './env-config';

export const fetchUsersInGroup = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}users`,
    headers: { Authorization: token },
  });
  if (response.status !== 200) {
    throw new Error(`error response not 200 when fetching users ${JSON.stringify(response)}`);
  }
  return response.data;
};

export const fetchAllUsers = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}all-users`,
    headers: { Authorization: token },
  });
  if (response.status !== 200) {
    throw new Error(`error response not 200 when fetching all users ${JSON.stringify(response)}`);
  }
  return response.data;
};

export const addUser = async (
  token: string,
  admin: boolean,
  firstName: string,
  surname: string,
  email: string,
  dept: string
) => {
  const config = await Config();
  try {
    const response = await Promise.resolve(
      axios({
        method: 'POST',
        url: `${config.adminAPI}users`,
        headers: { Authorization: token },
        data: {
          email: email,
          dept: dept,
          firstName: firstName,
          surname: surname,
          admin: admin,
          receiveCARNotification: true,
        },
      })
    );
    if (response.status !== 200) {
      throw new Error(`error response not 200 ${JSON.stringify(response)}`);
    }
    return response.data;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at POST to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at POST to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const deleteUser = async (token: string, userEmails: string[], dept: string) => {
  const config = await Config();
  try {
    const responses = await Promise.all(
      userEmails.map(async (user) => {
        const response = await axios({
          method: 'DELETE',
          url: `${config.adminAPI}users`,
          headers: { Authorization: token },
          data: {
            email: user,
            dept: dept,
          },
        });
        return response.data;
      })
    );
    return responses;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at PUT to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at PUT to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const enableDisableUsers = async (token: string, userEmails: string[], dept: string, disabled: boolean) => {
  const config = await Config();
  try {
    const responses = await Promise.all(
      userEmails.map(async (user) => {
        const response = await axios({
          method: 'PUT',
          url: `${config.adminAPI}users`,
          headers: { Authorization: token },
          data: {
            email: user,
            dept: dept,
            disabled: disabled,
          },
        });
        return response.data;
      })
    );
    return responses;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at PUT to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at PUT to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const updateAdminStatus = async (token: string, userEmails: string[], dept: string, admin: boolean) => {
  const config = await Config();
  try {
    const responses = await Promise.all(
      userEmails.map(async (user) => {
        const response = await axios({
          method: 'PUT',
          url: `${config.adminAPI}users`,
          headers: { Authorization: token },
          data: {
            email: user,
            dept: dept,
            admin: admin,
          },
        });
        return response.data;
      })
    );
    return responses;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at PUT to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at PUT to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const updateCARNotificationStatus = async (
  token: string,
  userEmails: string[],
  dept: string,
  updatedStatus: boolean
) => {
  const config = await Config();
  try {
    const responses = await Promise.all(
      userEmails.map(async (user) => {
        const response = await axios({
          method: 'PUT',
          url: `${config.adminAPI}users`,
          headers: { Authorization: token },
          data: {
            email: user,
            dept: dept,
            receiveCARNotification: updatedStatus,
          },
        });
        return response.data;
      })
    );
    return responses;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at PUT to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at PUT to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const fetchAllRecipients = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}all-recipients`,
    headers: { Authorization: token },
  });

  if (response.status !== 200) {
    throw new Error(`error response not 200 when fetching all recipients ${JSON.stringify(response)}`);
  }

  return response.data;
};

export const fetchRecipientsInGroup = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}recipients`,
    headers: { Authorization: token },
  });

  return response.data;
};

export const addRecipient = async (token: string, firstName: string, surname: string, email: string, dept: string) => {
  const config = await Config();
  try {
    const response = await axios({
      method: 'POST',
      url: `${config.adminAPI}recipients`,
      headers: { Authorization: token },
      data: {
        email: email,
        dept: dept,
        firstName: firstName,
        surname: surname,
      },
    });
    if (response.status !== 200) {
      throw new Error(`error response not 200 ${JSON.stringify(response)}`);
    }
    return response.data;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at POST to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at POST to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const deleteRecipient = async (token: string, listOfRecipients: { email: string; dept: string }[]) => {
  const config = await Config();
  try {
    const responses = await Promise.all(
      listOfRecipients.map(async (recipient) => {
        const response = await axios({
          method: 'DELETE',
          url: `${config.adminAPI}recipients`,
          headers: { Authorization: token },
          data: {
            email: recipient.email,
            dept: recipient.dept,
          },
        });
        return response.data;
      })
    );
    return responses;
  } catch (e) {
    console.log('API call error:\n', JSON.stringify(e));
    if (e instanceof Error) {
      throw new Error(`Error at DELETE to Admin API. Caused by: ${e.message}`);
    } else {
      throw new Error(`Error at DELETE to Admin API. Caused by: ${JSON.stringify(e)}`);
    }
  }
};

export const getAnnouncements = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}messages`,
    headers: { Authorization: token },
  });

  if (response.status !== 200) {
    throw new Error(`error response not 200 when fetching announcements ${JSON.stringify(response)}`);
  }
  return response.data;
};

export const getAllAnnouncements = async (token: string) => {
  const config = await Config();
  const response = await axios({
    method: 'GET',
    url: `${config.adminAPI}messages`,
    headers: { Authorization: token },
    params: { ['showExpiredMessages']: 'true' },
  });

  if (response.status !== 200) {
    throw new Error(`error response not 200 when fetching announcements ${JSON.stringify(response)}`);
  }
  return response.data;
};

// /POST on message api
export const addAnnouncement = async (
  token: string,
  messageBody: string | undefined,
  displayUntilDate: string | undefined,
  pinned: boolean
) => {
  const config = await Config();
  const response = await axios({
    method: 'POST',
    url: `${config.adminAPI}messages`,
    headers: { Authorization: token },
    data: { body: messageBody, displayUntilDate, pinned },
  });
  if (response.status !== 200) {
    throw new Error(`error response not 200 ${JSON.stringify(response)}`);
  }
  return response.data;
};

// /PUT on message api
export const editAnnouncement = async (
  token: string,
  messageID: string | undefined,
  messageBody: string | undefined,
  displayUntilDate: string | undefined,
  pinned: boolean
) => {
  const config = await Config();
  const response = await axios({
    method: 'PUT',
    url: `${config.adminAPI}messages`,
    headers: { Authorization: token },
    data: { id: messageID, body: messageBody, displayUntilDate, pinned },
  });
  if (response.status !== 200) {
    throw new Error(`error response not 200 ${JSON.stringify(response)}`);
  }
  return response.data;
};
