import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import {
  openAddUserDialog,
  openDeleteUserDialog,
  openDisableCARDialog,
  openDisableUserDialog,
  openEnableCARDialog,
  openEnableUserDialog,
  openGrantAdminDialog,
  openRevokeAdminDialog,
} from './DeptUserAdminSlice';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AddModeratorRoundedIcon from '@mui/icons-material/AddModeratorRounded';
import RemoveModeratorRoundedIcon from '@mui/icons-material/RemoveModeratorRounded';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

const TableActions = () => {
  const rowsSelected = useAppSelector((state) => state.departmentAdmin.rowsSelected);
  const dispatch = useAppDispatch();
  return (
    <>
      {/*###################ADD/REMOVE USER BUTTONS###################*/}
      <ButtonGroup variant="outlined" sx={{ margin: '0.75vh' }}>
        <Tooltip title="Add User">
          <IconButton
            id="btn-adm-add"
            data-testid="openAddUserDialog"
            sx={{ margin: '0.8vh' }}
            onClick={() => {
              dispatch(openAddUserDialog());
            }}
          >
            <PersonAddAlt1RoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete User">
          <IconButton
            id="btn-adm-delete"
            data-testid="openDeleteUserDialog"
            sx={{ margin: '0.8vh' }}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openDeleteUserDialog());
            }}
          >
            <PersonRemoveRoundedIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
      {/*###################ENABLE/DISABLE USER BUTTONS###################*/}
      <ButtonGroup variant="contained" sx={{ margin: '0.75vh', backgroundColor: 'rgb(0 11 41)', borderRadius: '14px' }}>
        <Tooltip title="Enable Selected Users">
          <IconButton
            id="btn-adm-enable-users"
            data-testid="openEnableUserDialog"
            sx={[
              { margin: '0.8vh' },
              { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } },
              { '&:disabled > svg': { color: 'rgb(38, 85, 40)' } },
            ]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openEnableUserDialog());
            }}
          >
            <PersonRoundedIcon sx={{ color: 'rgb(76, 175, 80)' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Disable Selected Users">
          <IconButton
            id="btn-adm-disable-users"
            data-testid="openDisableUserDialog"
            sx={[
              { margin: '0.8vh' },
              { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } },
              { '&:disabled > svg': { color: 'rgb(122, 34, 27)' } },
            ]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openDisableUserDialog());
            }}
          >
            <PersonRoundedIcon sx={{ color: 'rgb(244, 67, 54)' }} />
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      {/*###################ADMIN PRIVILEGES BUTTONS###################*/}
      <ButtonGroup variant="contained" sx={{ margin: '0.75vh', backgroundColor: 'rgb(0 11 41)', borderRadius: '14px' }}>
        <Tooltip title="Grant Admin Privileges">
          <IconButton
            id="btn-adm-promote-user"
            data-testid="openGrantAdminDialog"
            sx={[{ margin: '0.8vh' }, { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } }]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openGrantAdminDialog());
            }}
          >
            <AddModeratorRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Revoke Admin Privileges">
          <IconButton
            data-testid="openRevokeAdminDialog"
            sx={[{ margin: '0.8vh' }, { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } }]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openRevokeAdminDialog());
            }}
          >
            <RemoveModeratorRoundedIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      {/*###################CAR NOTIFICATION BUTTONS###################*/}
      <ButtonGroup variant="contained" sx={{ margin: '0.75vh', backgroundColor: 'rgb(0 11 41)', borderRadius: '14px' }}>
        <Tooltip title="Enable CAR Emails">
          <IconButton
            id="btn-adm-toggle-car"
            data-testid="openEnableCARDialog"
            sx={[{ margin: '0.8vh' }, { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } }]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openEnableCARDialog());
            }}
          >
            <NotificationsActiveRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Disable CAR Emails">
          <IconButton
            data-testid="openDisableCARDialog"
            sx={[{ margin: '0.8vh' }, { '&:hover': { backgroundColor: 'rgb(48, 67, 83)' } }]}
            disabled={!(rowsSelected.length > 0)}
            onClick={() => {
              dispatch(openDisableCARDialog());
            }}
          >
            <NotificationsOffRoundedIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </>
  );
};

export default TableActions;
