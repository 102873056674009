// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Announcements_container__1iSur {\n  width: 60%;\n  margin-left: 20%;\n  max-height: 65vh;\n  border-radius: 6px;\n  overflow: auto;\n}\n\n.Announcements_loadingContainer__tHolm {\n  overflow: hidden;\n}\n\n.Announcements_title__nishV {\n  margin: 0;\n}\n\n.Announcements_body__Qp9\\+m,\n.Announcements_body__Qp9\\+m > * {\n  text-align: left;\n  margin: 0;\n}\n\nh1 {\n  font-size: 28px;\n}\n\nh2 {\n  font-size: 20px;\n}\n\nh3 {\n  font-size: 17px;\n}\n", "",{"version":3,"sources":["webpack://./src/Announcements/Announcements.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;;EAEE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  width: 60%;\n  margin-left: 20%;\n  max-height: 65vh;\n  border-radius: 6px;\n  overflow: auto;\n}\n\n.loadingContainer {\n  overflow: hidden;\n}\n\n.title {\n  margin: 0;\n}\n\n.body,\n.body > * {\n  text-align: left;\n  margin: 0;\n}\n\nh1 {\n  font-size: 28px;\n}\n\nh2 {\n  font-size: 20px;\n}\n\nh3 {\n  font-size: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Announcements_container__1iSur",
	"loadingContainer": "Announcements_loadingContainer__tHolm",
	"title": "Announcements_title__nishV",
	"body": "Announcements_body__Qp9+m"
};
export default ___CSS_LOADER_EXPORT___;
