import React from 'react';
import { styled } from '@mui/material/styles';

const ServiceStatusDiv = styled('div')({
  top: 30,
  right: 10,
  position: 'absolute',
  color: 'white',
});

const ServiceStatus = () => {
  return <ServiceStatusDiv>Service Status: Beta</ServiceStatusDiv>;
};

export default ServiceStatus;
