import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addUser as addUserAPI,
  deleteUser as deleteUserAPI,
  addRecipient as addRecipientAPI,
  deleteRecipient as deleteRecipientAPI,
  enableDisableUsers as enableDisableUsersAPI,
  fetchAllUsers,
  fetchUsersInGroup,
  fetchAllRecipients,
  updateAdminStatus,
  updateCARNotificationStatus,
  fetchRecipientsInGroup,
} from '../Api';
import { getDecodedToken, getEmailFromToken } from '../auth-utils';
import { AddType } from './AddDialog';

export type Row = {
  dept: string;
  admin: boolean;
  email: string;
  firstName: string;
  surname: string;
  id: number | undefined;
  receiveCARNotification: boolean;
  disabled: boolean;
  disabledReason: string | null;
};

export type RecipientsRow = {
  id: number | undefined;
  email: string;
  dept: string;
  firstName: string;
  surname: string;
};

type PartialWithRequired<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type PartialRow = PartialWithRequired<Row, 'dept' | 'email'>;

export type DepartmentAdminState = {
  showDeleteUsersDialog: boolean;
  showDeleteRecipientDialog: boolean;
  showAddUserDialog: boolean;
  showAddRecipientDialog: boolean;
  showEnableUsersDialog: boolean;
  showDisableUsersDialog: boolean;
  showGrantAdminDialog: boolean;
  showRevokeAdminDialog: boolean;
  showEnableCARDialog: boolean;
  showDisableCARDialog: boolean;
  rowsSelected: number[];
  recipientRowsSelected: number[];
  selectedUserEmails: string[];
  selectedRecipientEmails: { email: string; dept: string }[];
  userIsLoading: boolean;
  recipientsIsLoading: boolean;
  rowsData: Row[];
  recipientRowData: RecipientsRow[];
  uniqueEmailDomains: string[];
  recipientUniqueEmailDomains: string[];
  snackbarOpen: boolean;
  errorMessage: string | undefined;
  departments: string[];
  usersDeptOrSupersSelectedDept: string;
  ncscSuper: boolean;
  addType: AddType | undefined;
};

const initialState: DepartmentAdminState = {
  showDeleteUsersDialog: false,
  showDeleteRecipientDialog: false,
  showAddUserDialog: false,
  showAddRecipientDialog: false,
  showEnableUsersDialog: false,
  showDisableUsersDialog: false,
  showGrantAdminDialog: false,
  showRevokeAdminDialog: false,
  showEnableCARDialog: false,
  showDisableCARDialog: false,
  rowsSelected: [],
  recipientRowsSelected: [],
  selectedUserEmails: [],
  selectedRecipientEmails: [],
  userIsLoading: true,
  recipientsIsLoading: true,
  rowsData: [],
  recipientRowData: [],
  uniqueEmailDomains: [],
  recipientUniqueEmailDomains: [],
  snackbarOpen: false,
  errorMessage: '',
  departments: new Array<string>(),
  usersDeptOrSupersSelectedDept: '',
  ncscSuper: false,
  addType: undefined,
};

export const getAllPortalUsersFromDept = createAsyncThunk(
  'DepartmentAdmin/getAllPortalUsersFromDept',
  async (token: string) => {
    return (await fetchUsersInGroup(token)) as Row[];
  }
);

export const getAllPortalUsers = createAsyncThunk('DepartmentAdmin/getAllPortalUsers', async (token: string) => {
  return (await fetchAllUsers(token)) as Row[];
});

export const getAllRecipients = createAsyncThunk('DepartmentAdmin/getAllRecipients', async (token: string) => {
  return (await fetchAllRecipients(token)) as RecipientsRow[];
});

export const getAllPortalRecipientsFromDept = createAsyncThunk(
  'DepartmentAdmin/getAllPortalRecipientsFromDept',
  async (token: string) => {
    return (await fetchRecipientsInGroup(token)) as RecipientsRow[];
  }
);

export const addUser = createAsyncThunk(
  'DepartmentAdmin/addUser',
  async (addUserData: {
    token: string;
    admin: boolean;
    firstname: string;
    surname: string;
    email: string;
    dept: string;
  }) => {
    return await addUserAPI(
      addUserData.token,
      addUserData.admin,
      addUserData.firstname,
      addUserData.surname,
      addUserData.email,
      addUserData.dept
    );
  }
);

export const addRecipient = createAsyncThunk(
  'DepartmentAdmin/addRecipient',
  async (addRecipientData: { token: string; firstName: string; surname: string; email: string; dept: string }) => {
    return await addRecipientAPI(
      addRecipientData.token,
      addRecipientData.firstName,
      addRecipientData.surname,
      addRecipientData.email,
      addRecipientData.dept
    );
  }
);

export const deleteRecipient = createAsyncThunk(
  'DepartmentAdmin/deleteRecipient',
  async (deleteRecipientData: { token: string; recipientsToDelete: { email: string; dept: string }[] }) => {
    return await deleteRecipientAPI(deleteRecipientData.token, deleteRecipientData.recipientsToDelete);
  }
);

export const deleteUser = createAsyncThunk(
  'DepartmentAdmin/deleteUser',
  async (deleteUserData: { token: string; usersByEmail: string[]; userdept: string }) => {
    return (await deleteUserAPI(
      deleteUserData.token,
      deleteUserData.usersByEmail,
      deleteUserData.userdept
    )) as PartialRow[];
  }
);

export const enableUser = createAsyncThunk(
  'DepartmentAdmin/enableUser',
  async (disableUserData: { token: string; users: string[]; dept: string }) => {
    return (await enableDisableUsersAPI(
      disableUserData.token,
      disableUserData.users,
      disableUserData.dept,
      false
    )) as PartialRow[];
  }
);

export const disableUser = createAsyncThunk(
  'DepartmentAdmin/disableUser',
  async (enableUserData: { token: string; users: string[]; dept: string }) => {
    return (await enableDisableUsersAPI(
      enableUserData.token,
      enableUserData.users,
      enableUserData.dept,
      true
    )) as PartialRow[];
  }
);

export const grantAdmin = createAsyncThunk(
  'DepartmentAdmin/grantAdmin',
  async (updateAdminStatusData: { token: string; users: string[]; dept: string }) => {
    return (await updateAdminStatus(
      updateAdminStatusData.token,
      updateAdminStatusData.users,
      updateAdminStatusData.dept,
      true
    )) as PartialRow[];
  }
);

export const revokeAdmin = createAsyncThunk(
  'DepartmentAdmin/revokeAdmin',
  async (updateAdminStatusThunkData: { token: string; users: string[]; dept: string }) => {
    return (await updateAdminStatus(
      updateAdminStatusThunkData.token,
      updateAdminStatusThunkData.users,
      updateAdminStatusThunkData.dept,
      false
    )) as PartialRow[];
  }
);

export const enableCARNotification = createAsyncThunk(
  'DepartmentAdmin/enableReceiveCARNotification',
  async (updateReceiveCARNotificationThunkData: { token: string; users: string[]; dept: string }) => {
    return (await updateCARNotificationStatus(
      updateReceiveCARNotificationThunkData.token,
      updateReceiveCARNotificationThunkData.users,
      updateReceiveCARNotificationThunkData.dept,
      true
    )) as PartialRow[];
  }
);

export const disableCARNotification = createAsyncThunk(
  'DepartmentAdmin/disableReceiveCARNotification',
  async (updateReceiveCARNotificationThunkData: { token: string; users: string[]; dept: string }) => {
    return (await updateCARNotificationStatus(
      updateReceiveCARNotificationThunkData.token,
      updateReceiveCARNotificationThunkData.users,
      updateReceiveCARNotificationThunkData.dept,
      false
    )) as PartialRow[];
  }
);

export const deptAdminSlice = createSlice({
  name: 'DepartmentAdmin',
  initialState,
  reducers: {
    rowSelection: (state, action: PayloadAction<number[]>) => {
      state.rowsSelected = action.payload;
      state.selectedUserEmails = [];
      action.payload.map((rowIndex) => {
        const currentUserEmail = getEmailFromToken(getDecodedToken());
        const selectedRowEmail = state.rowsData[rowIndex].email;
        // only perform actions against other people, not yourself.
        if (currentUserEmail !== selectedRowEmail) {
          state.selectedUserEmails.push(selectedRowEmail);
        }
      });
    },
    recipientRowsSelection: (state, action: PayloadAction<number[]>) => {
      state.recipientRowsSelected = action.payload;
      state.selectedRecipientEmails = [];
      action.payload.map((rowIndex) => {
        const currentRecipientEmail = getEmailFromToken(getDecodedToken());
        const selectedRecipientRowEmail = state.recipientRowData[rowIndex].email;
        const selectedRecipientDepartment = state.recipientRowData[rowIndex].dept;
        if (currentRecipientEmail !== selectedRecipientRowEmail) {
          state.selectedRecipientEmails.push({ email: selectedRecipientRowEmail, dept: selectedRecipientDepartment });
        }
      });
    },
    setUsersDept: (state, action) => {
      state.usersDeptOrSupersSelectedDept = action.payload; // Defaults to user's department. And only changes if users is NCSCSuper
    },
    openAddUserDialog: (state) => {
      state.addType = AddType.User;
      state.showAddUserDialog = true;
    },
    openAddRecipientDialog: (state) => {
      state.addType = AddType.Recipient;
      state.showAddUserDialog = true;
    },
    openDeleteUserDialog: (state) => {
      state.showDeleteUsersDialog = true;
    },
    openDeleteRecipientDialog: (state) => {
      state.showDeleteRecipientDialog = true;
    },
    openEnableUserDialog: (state) => {
      state.showEnableUsersDialog = true;
    },
    openDisableUserDialog: (state) => {
      state.showDisableUsersDialog = true;
    },
    openGrantAdminDialog: (state) => {
      state.showGrantAdminDialog = true;
    },
    openRevokeAdminDialog: (state) => {
      state.showRevokeAdminDialog = true;
    },
    openEnableCARDialog: (state) => {
      state.showEnableCARDialog = true;
    },
    openDisableCARDialog: (state) => {
      state.showDisableCARDialog = true;
    },
    closeAddUserDialog: (state) => {
      state.showAddUserDialog = false;
    },
    closeDeleteUserDialog: (state) => {
      state.showDeleteUsersDialog = false;
    },
    closeEnableUserDialog: (state) => {
      state.showEnableUsersDialog = false;
    },
    closeDisableUserDialog: (state) => {
      state.showDisableUsersDialog = false;
    },
    closeGrantAdminDialog: (state) => {
      state.showGrantAdminDialog = false;
    },
    closeRevokeAdminDialog: (state) => {
      state.showRevokeAdminDialog = false;
    },
    closeEnableCARDialog: (state) => {
      state.showEnableCARDialog = false;
    },
    closeDisableCARDialog: (state) => {
      state.showDisableCARDialog = false;
    },
    snackBarClosed: (state) => {
      state.snackbarOpen = false;
      state.errorMessage = '';
    },
    resetDeptAdmin: () => {
      return initialState;
    },
    deptChanged: (state, action) => {
      state.usersDeptOrSupersSelectedDept = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(addUser.pending, (state) => {
      state.userIsLoading = true;
      state.showAddUserDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      let uniqueDomains: string[] = [];
      let updatedRows = state.rowsData;
      updatedRows.push(action.payload);
      updatedRows.forEach((row: Row, index) => {
        row.id = index;
        let email = row.email;
        uniqueDomains.push(email.split('@', 2)[1]);
      });
      state.uniqueEmailDomains = uniqueDomains;
      state.rowsData = updatedRows;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(addUser.rejected, (state) => {
      state.errorMessage = 'Error adding user, if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.userIsLoading = true;
      state.showDeleteUsersDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.filter(
          (row: Row) => !(row.email === updatedData.email && row.dept === updatedData.dept)
        );
      });
      let updatedRows = state.rowsData; //Update row ids
      updatedRows.forEach((row: Row, index) => {
        row.id = index;
      });
      state.rowsData = updatedRows;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.errorMessage = 'Error deleting user(s), if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    // Add CAR Recipient
    builder.addCase(addRecipient.pending, (state) => {
      state.recipientsIsLoading = true;
      state.showAddRecipientDialog = false;
      state.recipientRowsSelected = [];
    });
    builder.addCase(addRecipient.fulfilled, (state, action) => {
      const recipientRows = state.recipientRowData;
      recipientRows.push(action.payload);
      const uniqueDomains: string[] = [];
      state.recipientRowData.forEach((row: RecipientsRow, index) => {
        row.id = index;
        let email = row.email;
        uniqueDomains.push(email.split('@', 2)[1]);
      });
      state.recipientUniqueEmailDomains = uniqueDomains;
      state.recipientRowData = recipientRows;
      state.recipientsIsLoading = false;
      state.recipientRowsSelected = [];
    });
    builder.addCase(addRecipient.rejected, (state) => {
      state.errorMessage = 'Error adding recipient(s), if this persists please contact support';
      state.snackbarOpen = true;
      state.recipientsIsLoading = false;
      state.recipientRowsSelected = [];
    });
    // Delete CAR Recipient
    builder.addCase(deleteRecipient.pending, (state) => {
      state.recipientsIsLoading = true;
      state.showDeleteRecipientDialog = false;
      state.recipientRowsSelected = [];
    });
    builder.addCase(deleteRecipient.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.recipientRowData = state.recipientRowData.filter(
          (row: RecipientsRow) => !(row.email === updatedData.email && row.dept === updatedData.dept)
        );
      });
      let updatedRows = state.recipientRowData;
      updatedRows.forEach((row, index) => {
        row.id = index;
      });
      state.recipientRowData = updatedRows;
      state.recipientsIsLoading = false;
      state.recipientRowsSelected = [];
    });
    builder.addCase(deleteRecipient.rejected, (state) => {
      state.errorMessage = 'Error deleting recipient(s), if this persists please contact support';
      state.snackbarOpen = true;
      state.recipientsIsLoading = false;
      state.recipientRowsSelected = [];
    });
    builder.addCase(enableUser.pending, (state) => {
      state.userIsLoading = true;
      state.showEnableUsersDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(enableUser.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.disabled === 'boolean') {
              row = {
                ...row,
                disabled: updatedData.disabled,
              };
            }
            if (updatedData.disabledReason) {
              row = {
                ...row,
                disabledReason: updatedData.disabledReason,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(enableUser.rejected, (state) => {
      state.errorMessage = `Error enabling ${
        state.rowsSelected.length > 1 ? 'users' : 'user'
      }, if this persists please contact support`;
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableUser.pending, (state) => {
      state.userIsLoading = true;
      state.showDisableUsersDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableUser.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.disabled === 'boolean') {
              row = {
                ...row,
                disabled: updatedData.disabled,
              };
            }
            if (updatedData.disabledReason) {
              row = {
                ...row,
                disabledReason: updatedData.disabledReason,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableUser.rejected, (state) => {
      state.errorMessage = `Error disabling ${
        state.rowsSelected.length > 1 ? 'users' : 'user'
      }, if this persists please contact support`;
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(grantAdmin.pending, (state) => {
      state.userIsLoading = true;
      state.showGrantAdminDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(grantAdmin.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.admin === 'boolean') {
              row = {
                ...row,
                admin: updatedData.admin,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(grantAdmin.rejected, (state) => {
      state.errorMessage = 'Error granting admin, if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(revokeAdmin.pending, (state) => {
      state.userIsLoading = true;
      state.showRevokeAdminDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(revokeAdmin.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.admin === 'boolean') {
              row = {
                ...row,
                admin: updatedData.admin,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(revokeAdmin.rejected, (state) => {
      state.errorMessage = 'Error revoking admin, if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(enableCARNotification.pending, (state) => {
      state.userIsLoading = true;
      state.showEnableCARDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(enableCARNotification.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.receiveCARNotification === 'boolean') {
              row = {
                ...row,
                receiveCARNotification: updatedData.receiveCARNotification,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(enableCARNotification.rejected, (state) => {
      state.errorMessage = 'Error enabling CAR notifications, if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableCARNotification.pending, (state) => {
      state.userIsLoading = true;
      state.showDisableCARDialog = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableCARNotification.fulfilled, (state, action) => {
      action.payload.map((updatedData: PartialRow) => {
        state.rowsData = state.rowsData.map((row: Row) => {
          if (row.email === updatedData.email && row.dept === updatedData.dept) {
            if (typeof updatedData.receiveCARNotification === 'boolean') {
              row = {
                ...row,
                receiveCARNotification: updatedData.receiveCARNotification,
              };
            }
          }
          return row;
        });
      });
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(disableCARNotification.rejected, (state) => {
      state.errorMessage = 'Error disabling CAR noficiations, if this persists please contact support';
      state.snackbarOpen = true;
      state.userIsLoading = false;
      state.rowsSelected = [];
    });
    builder.addCase(getAllRecipients.pending, (state) => {
      state.recipientsIsLoading = true;
    });
    builder.addCase(getAllRecipients.fulfilled, (state, action) => {
      const recipientRows = action.payload;
      const uniqueDomains: string[] = [];
      recipientRows.forEach((row: RecipientsRow, index) => {
        row.id = index;
        let email = row.email;
        uniqueDomains.push(email.split('@', 2)[1]);
      });
      state.recipientUniqueEmailDomains = uniqueDomains;
      state.recipientRowData = recipientRows;
      state.recipientsIsLoading = false;
    });
    builder.addCase(getAllRecipients.rejected, (state, action) => {
      state.errorMessage = 'Error fetching recipients, if this persists please contact support';
      state.snackbarOpen = true;
      state.recipientsIsLoading = false;
    });
    builder.addCase(getAllPortalRecipientsFromDept.pending, (state) => {
      state.recipientsIsLoading = true;
    });
    builder.addCase(getAllPortalRecipientsFromDept.fulfilled, (state, action) => {
      const recipientRows = action.payload;
      const uniqueDomains: string[] = [];
      recipientRows.forEach((row: RecipientsRow, index) => {
        row.id = index;
        let email = row.email;
        uniqueDomains.push(email.split('@', 2)[1]);
      });
      state.recipientUniqueEmailDomains = uniqueDomains;
      state.recipientRowData = recipientRows;
      state.recipientsIsLoading = false;
    });
    builder.addCase(getAllPortalRecipientsFromDept.rejected, (state, action) => {
      state.errorMessage = 'Error fetching recipients, if this persists please contact support';
      state.snackbarOpen = true;
      state.recipientsIsLoading = false;
    });
    builder.addMatcher(
      (action) =>
        action.type === 'DepartmentAdmin/getAllPortalUsersFromDept/pending' ||
        action.type === 'DepartmentAdmin/getAllPortalUsers/pending',
      (state) => {
        state.userIsLoading = true;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type === 'DepartmentAdmin/getAllPortalUsersFromDept/fulfilled' ||
        action.type === 'DepartmentAdmin/getAllPortalUsers/fulfilled',
      (state, action) => {
        let uniqueDomains: string[] = [];
        let updatedRows;
        if (Array.isArray(action.payload)) {
          updatedRows = action.payload;
        } else {
          updatedRows = [action.payload];
        }
        const setOfDepts = new Set<string>();
        updatedRows.forEach((row: Row, index) => {
          row.id = index;
          let email = row.email;
          uniqueDomains.push(email.split('@', 2)[1]);
          setOfDepts.add(row.dept);
        });

        // Get all users can only be called by a NCSCSuper user.
        if (action.type === 'DepartmentAdmin/getAllPortalUsers/fulfilled') {
          state.ncscSuper = true;
          state.departments = Array.from(setOfDepts).sort();
          // Set the first department in the set as the current department.
          state.usersDeptOrSupersSelectedDept = state.departments[0];
        }
        state.uniqueEmailDomains = uniqueDomains;
        state.rowsData = updatedRows;
        state.userIsLoading = false;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type === 'DepartmentAdmin/getAllPortalUsersFromDept/rejected' ||
        action.type === 'DepartmentAdmin/getAllPortalUsers/rejected',
      (state, action) => {
        state.errorMessage = 'Error adding fetching users, if this persists please contact support';
        state.snackbarOpen = true;
        state.userIsLoading = false;
        state.rowsSelected = [];
      }
    );
  },
});

export const {
  rowSelection,
  recipientRowsSelection,
  setUsersDept,
  openAddUserDialog,
  openAddRecipientDialog,
  openDeleteUserDialog,
  openDeleteRecipientDialog,
  openDisableCARDialog,
  openDisableUserDialog,
  openEnableCARDialog,
  openEnableUserDialog,
  openRevokeAdminDialog,
  openGrantAdminDialog,
  closeAddUserDialog,
  closeDeleteUserDialog,
  closeDisableCARDialog,
  closeDisableUserDialog,
  closeEnableCARDialog,
  closeEnableUserDialog,
  closeGrantAdminDialog,
  closeRevokeAdminDialog,
  snackBarClosed,
  resetDeptAdmin,
  deptChanged,
} = deptAdminSlice.actions;

export default deptAdminSlice.reducer;
