import React, { useEffect, useState } from 'react';
import Announcement from './Announcement';
import styles from './Announcements.module.css';
import { getAnnouncements } from '../Api';
import { CircularProgress } from '@mui/material';
import { getToken } from '../auth-utils';

export type AnnouncementEntry = {
  body: string;
  pinned: boolean;
  displayUntilDate?: string | null;
  createdDate: string;
};

const AnnouncementList = () => {
  const [data, setData] = useState<AnnouncementEntry[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);

  const getAndSortAnnouncements = async (token: string) => {
    const data: AnnouncementEntry[] = await getAnnouncements(getToken());
    data.sort((a, b) => {
      return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    });
    data.sort((a, b) => Number(b.pinned) - Number(a.pinned));
    setLoading(false);
    setData(data);
  };

  useEffect(() => {
    getAndSortAnnouncements(getToken());
  }, []);

  return (
    <div className={loading ? styles.loadingContainer : styles.container}>
      {loading ? (
        <CircularProgress className={styles.loading} aria-label={'Announcements loading'} />
      ) : (
        data.map((announcement, index) => (
          <Announcement key={index} body={announcement.body} pinned={announcement.pinned} aria-label="Announcement" />
        ))
      )}
    </div>
  );
};

export default AnnouncementList;
