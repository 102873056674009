import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import pdf from './HBC-Portal-2FA-Setup.pdf';
import { AuthErrorEnum } from './auth-utils';

interface Props {
  children: React.ReactNode;
}

const Title = ({ children }: Props) => (
  <Typography align="center" variant="h4" color="white" sx={{ m: 4 }}>
    {children}
  </Typography>
);

const Body = ({ children }: Props) => (
  <Typography align="center" variant="body1" color="white" sx={{ m: 4 }}>
    {children}
  </Typography>
);

const TwoFALink = ({ children }: Props) => (
  <Link href={pdf} sx={{ fontSize: '20px', m: 4 }}>
    {children}
  </Link>
);

const SupportEmail = () => (
  <a style={{ color: 'white' }} href="mailto:host@ncsc.gov.uk">
    host@ncsc.gov.uk
  </a>
);

const renderErrorMessage = (errorType: AuthErrorEnum) => {
  switch (errorType) {
    case AuthErrorEnum.BROKEN_TOTP:
      return (
        <React.Fragment>
          <Title>Authentication Error</Title>
          <Body>
            A configuration error occurred with your Two Factor Authentication (2FA) setup. Please retry the 2FA
            instructions below. If the problem persists, contact Support (<SupportEmail />
            ).
          </Body>
          <TwoFALink>Download 2FA Setup Guide</TwoFALink>
        </React.Fragment>
      );
    case AuthErrorEnum.NO_TOTP:
      return (
        <React.Fragment>
          <Title>Authentication Error</Title>
          <Body>
            Two Factor Authentication is required in order to use the HBC Portal. Please follow the instructions below
            to setup 2FA.
          </Body>
          <TwoFALink>Download 2FA Setup Guide</TwoFALink>
        </React.Fragment>
      );
    case AuthErrorEnum.NO_MAPPING:
      return (
        <React.Fragment>
          <Title>Authorisation Error</Title>
          <Body>
            You are not authorized to access the HBC Portal. Please contact your Team Lead or Support (
            <SupportEmail />) to ensure you have be granted the correct permissions.
          </Body>
        </React.Fragment>
      );
    case AuthErrorEnum.MULTI_MAPPING:
      return (
        <React.Fragment>
          <Title>Authorisation Error</Title>
          <Body>
            An unexpected error occurred during authorisation. Please contact Support (
            <SupportEmail />) stating the error - multi_mapping_error.
          </Body>
        </React.Fragment>
      );
    case AuthErrorEnum.ACCOUNT_DISABLED:
      return (
        <React.Fragment>
          <Title>Authorisation Error</Title>
          <Body>Your account has been disabled. Please contact your Team Lead</Body>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <Title>Unexpected Error</Title>
          <Body>
            An unexpected error occurred. Please contact Support (<SupportEmail />
            ).
          </Body>
        </React.Fragment>
      );
  }
};

interface AuthErrorMessageProps {
  errorType: AuthErrorEnum;
}

const AuthErrorMessage = (props: AuthErrorMessageProps) => {
  return <div style={{ maxWidth: '750px', margin: 'auto' }}>{renderErrorMessage(props.errorType)}</div>;
};

export default AuthErrorMessage;
