import React, { ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import NcscLogo from './ncsc_logo_white.svg';
import HbcLogo from './hbc-logo.png';
import AdminPage from './DeptUserAdminPage';
import AnnouncementAdminPage from './AnnouncementsAdminPage';
import {
  AuthErrorEnum,
  checkAdminStatus,
  getDecodedToken,
  getDeptFromRole,
  getErrorFromRole,
  getRoleFromToken,
} from './auth-utils';
import Home from './Home';
import { Auth } from 'aws-amplify';
import { Button, CircularProgress } from '@mui/material';
import AuthErrorMessage from './AuthErrorMessage';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

interface Children {
  children?: ReactNode;
}

const LogoWrapper = ({ children }: Children) => (
  <div
    style={{
      backgroundColor: '#051c48',
      textAlign: 'center',
      height: '100vh',
    }}
  >
    <div style={{ paddingTop: '40px' }}>
      <img src={NcscLogo} alt="NCSC Logo" aria-label="NCSC Logo" style={{ height: '100px', padding: '10px' }} />
      <img src={HbcLogo} alt="HBC Logo" aria-label="NCSC Logo" style={{ height: '100px', padding: '10px' }} />
    </div>
    {children}
  </div>
);

type LandingPageProps = {
  portalURL: string;
};

const LandingPage = (props: LandingPageProps) => {
  const [dept, setDept] = useState('');
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState<AuthErrorEnum | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // If a session is returned then the user is logged in.
    Auth.currentSession()
      .then((session) => {
        if (session) {
          setLoggedIn(true);
          try {
            const role = getRoleFromToken(getDecodedToken());
            setDept(getDeptFromRole(role));
            setAuthError(getErrorFromRole(role));
            setIsAdmin(checkAdminStatus(role));
          } catch (err) {
            console.error(err);
            console.trace(err);
            setAuthError(AuthErrorEnum.UNEXPECTED_ERROR);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        //Throws an error is no use is logged in, would rather it return nil for the session but not our code.
        console.error(err);
        setLoading(false);
      });
  }, []);

  const renderLandingPage = () => {
    if (loading) {
      return <CircularProgress aria-label={'App loading'} />;
    } else if (authError !== null) {
      return <AuthErrorMessage errorType={authError} />;
    } else {
      return (
        <>
          {loggedIn && (
            <div
              style={{
                position: 'absolute',
                top: '60px',
                right: '30px',
              }}
            >
              <Button
                aria-label="Log out"
                data-testid="logout-button"
                variant="contained"
                sx={{ marginRight: '1.75vh' }}
                onClick={() => {
                  Auth.signOut();
                }}
              >
                Logout
                <LogoutRoundedIcon />
              </Button>
            </div>
          )}
          <Routes>
            <Route
              path="*"
              element={<Home portalURL={props.portalURL} dept={dept} loggedIn={loggedIn} isAdmin={isAdmin} />}
            />
            <Route path="/department-admin" element={<AdminPage deptName={dept} />} />
            <Route path="/announcements-admin" element={<AnnouncementAdminPage deptName={dept} />} />
          </Routes>
        </>
      );
    }
  };

  return <LogoWrapper>{renderLandingPage()}</LogoWrapper>;
};

LandingPage.propTypes = {
  portalURL: PropTypes.string.isRequired,
};

export default LandingPage;
