import decodeTokenBody, { Token } from './TokenHandler';

export const getDecodedToken = (): Token => {
  try {
    return decodeTokenBody(getToken());
  } catch (e) {
    console.error(`error decoding token ${e}`);
    throw new Error("Authorisation Error - couldn't decode ID Token");
  }
};

export const getToken = (): string => {
  //Fetch token from localStorage
  const localStorageKeys = Object.keys(window.localStorage);
  const tokenKey = localStorageKeys.find((key) => {
    return key.includes('idToken');
  });

  if (tokenKey) {
    const token = window.localStorage.getItem(tokenKey);
    if (token) {
      return token;
    }
  }

  throw new Error("Authorisation Error - couldn't find idToken in local storage");
};

export const getRoleFromToken = (token: Token): string => {
  if (token) {
    if (token['cognito:preferred_role']) {
      return token['cognito:preferred_role'];
    } else {
      throw new Error('Fatal Error parsing role - no cognito:preferred_role set in auth token');
    }
  } else {
    throw new Error('Fatal Error parsing role - ');
  }
};

export const checkAdminStatus = (role: string): boolean => {
  return role.includes('role/es_admin_role_for');
};

// This could do with improving, if the format of the role changes this won't work.
export const getDeptFromRole = (role: string): string => {
  if (role.includes('_role_for_ncsc_super_')) {
    return 'NCSCSUPER';
  } else if (role.includes('_role_for_dept_')) {
    return role.split('_')[5].toUpperCase();
  }
  return '';
};

export const enum AuthErrorEnum {
  BROKEN_TOTP,
  NO_TOTP,
  NO_MAPPING,
  MULTI_MAPPING,
  ACCOUNT_DISABLED,
  UNEXPECTED_ERROR,
}

/**
 * get any auth error contained within the role.
 * @param role
 * @returns authError
 */
export const getErrorFromRole = (role: string): AuthErrorEnum | null => {
  // arn:aws:iam::${ACCOUNT_ID}:role/sso_broken_totp_${ENV_NAME}
  if (role.includes('role/sso_broken_totp_')) {
    return AuthErrorEnum.BROKEN_TOTP;
  }
  // arn:aws:iam::${ACCOUNT_ID}:role/sso_no_totp_${ENV_NAME}
  if (role.includes('role/sso_no_totp_')) {
    return AuthErrorEnum.NO_TOTP;
  }
  // arn:aws:iam::${ACCOUNT_ID}:role/sso_no_mapping_${ENV_NAME}
  if (role.includes('role/sso_no_mapping_')) {
    return AuthErrorEnum.NO_MAPPING;
  }
  // arn:aws:iam::${ACCOUNT_ID}:role/sso_multi_mapping_${ENV_NAME}
  if (role.includes('role/sso_multi_mapping_')) {
    return AuthErrorEnum.MULTI_MAPPING;
  }
  // arn:aws:iam::${ACCOUNT_ID}:role/sso_account_disabled_${ENV_NAME}
  if (role.includes('role/sso_account_disabled_')) {
    return AuthErrorEnum.ACCOUNT_DISABLED;
  }

  // arn:aws:iam::${ACCOUNT_ID}:role/es_${adminOrUser}_role_for_ncsc_super_${ENV_NAME}
  // arn:aws:iam::${ACCOUNT_ID}:role/es_${adminOrUser}_role_for_dept_${dept.toLowerCase()}_${ENV_NAME}
  // ... or cognito
  if (
    !role.includes('_role_for_ncsc_super_') &&
    !role.includes('_role_for_dept_') &&
    !role.includes('role/es_master_user_role_')
  ) {
    console.warn('User role[${role}] not recognised.');
  }

  return null;
};

export const getEmailFromToken = (token: Token): string => {
  if (token) {
    if (token.email) {
      return token.email;
    }
    throw new Error("Fatal Error getting user's email - email didn't exist in user token");
  }
  throw new Error("Fatal Error getting user's email - user token didn't exist");
};
