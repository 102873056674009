import React from 'react';
import { DataGrid, GridColDef, GridSelectionModel, GridCallbackDetails, GridFilterModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { recipientRowsSelection, RecipientsRow } from './DeptUserAdminSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import DisabledCell from './DisabledCell';
import Spinner from './Spinner';

const recipientsColumns: GridColDef[] = [
  {
    field: 'dept',
    headerName: 'Dept Name',
    width: 100,
    renderCell: (params) => {
      return <div data-testid="dept-field-rec">{params.row.dept}</div>;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 225,
    renderCell: (params) => {
      return <div data-testid="email-field-rec">{params.row.email}</div>;
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 150,
    renderCell: (params) => {
      return <div data-testid="firstname-field-rec">{params.row.firstName}</div>;
    },
  },
  {
    field: 'surname',
    headerName: 'Last Name',
    width: 150,
    renderCell: (params) => {
      return <div data-testid="surname-field-rec">{params.row.surname}</div>;
    },
  },
  {
    field: 'disabledReason',
    headerName: 'Account Status',
    width: 717,
    renderCell: (params) => {
      return (
        <DisabledCell
          recipient
          disabled={params.row.disabled}
          disabledReason={params.row.disabledReason}
          lastSuccessfulLogin={params.row.lastSuccessfulLogin}
        />
      );
    },
  },
];

const RecipientTable = () => {
  const dispatch = useAppDispatch();

  const ncscSuper = useAppSelector((state) => state.departmentAdmin.ncscSuper);
  const isLoading = useAppSelector((state) => state.departmentAdmin.recipientsIsLoading);
  const recipientRowsSelected = useAppSelector((state) => state.departmentAdmin.recipientRowsSelected);
  const recipientRowsData: RecipientsRow[] = useAppSelector((state) => state.departmentAdmin.recipientRowData);
  const usersDeptOrSupersSelectedDept: string = useAppSelector(
    (state) => state.departmentAdmin.usersDeptOrSupersSelectedDept
  );

  const generateFilter = (): GridFilterModel | undefined => {
    if (usersDeptOrSupersSelectedDept === '') {
      console.error('Tried to filter with no department selected!');
      return undefined;
    }
    return { items: [{ id: 1, columnField: 'dept', operatorValue: 'equals', value: usersDeptOrSupersSelectedDept }] };
  };

  return (
    <Box style={{ width: '100%', height: 400 }}>
      {/*Using Spinner insead of the default as it doesn't comply with Aria requirments*/}
      <DataGrid
        components={{
          LoadingOverlay: Spinner,
        }}
        loading={isLoading}
        filterModel={ncscSuper ? generateFilter() : undefined}
        disableColumnFilter
        columns={recipientsColumns}
        rows={recipientRowsData}
        columnBuffer={8}
        checkboxSelection={true}
        selectionModel={recipientRowsSelected}
        onSelectionModelChange={(model: GridSelectionModel, details: GridCallbackDetails) =>
          dispatch(recipientRowsSelection(model as number[]))
        }
        disableVirtualization={true}
      />
    </Box>
  );
};

export default RecipientTable;
