import { configureStore } from '@reduxjs/toolkit';
import adminAnnouncementsReducer from './AnnouncementsAdminPage/adminAnnouncementsSlice';
import deptAdminReducer from './DeptUserAdminPage/DeptUserAdminSlice';

export const store = configureStore({
  reducer: {
    adminAnnouncements: adminAnnouncementsReducer,
    departmentAdmin: deptAdminReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
