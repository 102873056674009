import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Title = (props: { title: string }) => (
  <Box
    style={{
      width: '100%',
      height: 'fit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
    }}
  >
    <Typography variant="h5">{props.title}</Typography>
  </Box>
);

export default Title;
