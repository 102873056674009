export interface Token {
  at_hash: string;
  aud: string;
  auth_time: number;
  'cognito:preferred_role': string;
  'cognito:username': string;
  'custom:totp': boolean;
  exp: number;
  iat: number;
  identities: [
    {
      dateCreated: number;
      issuer: null | string;
      primary: boolean;
      providerName: string;
      providerType: string;
      userId: string;
    }
  ];
  iss: string;
  jti: string;
  origin_jti: string;
  sub: string;
  token_use: string;
  email: string;
}

/**
 *
 * @param token Base64 encoded JWT
 * @throws Error if unable to decode the token.
 * Takes a JWT and returns it as a JSON object.
 */
const decodeTokenBody = (token: string): Token => {
  let tokenArray: string[];
  tokenArray = token.split('.');
  if (tokenArray.length !== 3) {
    throw new Error('Failed to split token');
  }
  try {
    return JSON.parse(atob(tokenArray[1])) as Token;
  } catch (e) {
    throw new Error('Unable to parse token');
  }
};

export default decodeTokenBody;
