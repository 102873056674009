import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { AnnouncementEntry } from '../AnnouncementsAdminPage/AdminAnnouncementsList';
import { addAnnouncement, editAnnouncement, getAllAnnouncements } from '../Api';

export type AdminAnnouncementState = {
  announcementData: AnnouncementEntry[];
  loading: boolean;
  openFormDialog: boolean;
  openConfirmDialog: boolean;
  openErrorDialog: boolean;
  editMode: boolean;
  latestError: string | undefined;
  announcementID: string | undefined;
  announcementBody: string | undefined;
  announcementDisplayUntilDate: string | undefined;
  announcementPinned: boolean;
};

export type OpenFormToEditPayload = {
  id: string;
  body: string;
  pinned: boolean;
  displayUntilDate: string | undefined;
};

const DEFAULT_BODY = 'Provide your announcement here...';

const initialState: AdminAnnouncementState = {
  announcementData: [],
  loading: true,
  openFormDialog: false,
  openConfirmDialog: false,
  openErrorDialog: false,
  editMode: false,
  latestError: undefined,
  announcementID: undefined,
  announcementBody: DEFAULT_BODY,
  announcementDisplayUntilDate: undefined,
  announcementPinned: false,
};

export const getAllAnnouncementsFromAPI = createAsyncThunk(
  'adminAnnouncements/getAllAnnouncements',
  async (token: string) => {
    return (await getAllAnnouncements(token)) as AnnouncementEntry[];
  }
);

export const sendNewAnnouncementToAPI = createAsyncThunk(
  'adminAnnouncements/addAnnouncement',
  async (newAnnouncementData: {
    token: string;
    body: string | undefined;
    pinned: boolean;
    displayUntilDate: string | undefined;
  }) => {
    return (await addAnnouncement(
      newAnnouncementData.token,
      newAnnouncementData.body,
      newAnnouncementData.displayUntilDate,
      newAnnouncementData.pinned
    )) as AnnouncementEntry;
  }
);

export const sendUpdatedAnnouncementToAPI = createAsyncThunk(
  'adminAnnouncements/updateAnnouncement',
  async (updatedAnnouncementData: {
    token: string;
    id: string | undefined;
    body: string | undefined;
    pinned: boolean;
    displayUntilDate: string | undefined;
  }) => {
    return (await editAnnouncement(
      updatedAnnouncementData.token,
      updatedAnnouncementData.id,
      updatedAnnouncementData.body,
      updatedAnnouncementData.displayUntilDate,
      updatedAnnouncementData.pinned
    )) as AnnouncementEntry;
  }
);

export const adminAnnouncementsSlice = createSlice({
  name: 'AdminAnnouncements',
  initialState,
  reducers: {
    openFormDialog: (state) => {
      state.openFormDialog = true;
    },
    closeFormDialog: (state) => {
      state.openFormDialog = false;
      state.editMode = false;
      state.announcementPinned = false;
      state.announcementDisplayUntilDate = undefined;
      state.announcementBody = DEFAULT_BODY;
    },
    openConfirmDialog: (state) => {
      state.openConfirmDialog = true;
    },
    closeConfirmDialog: (state) => {
      state.openConfirmDialog = false;
    },
    newError: (state, action: PayloadAction<string>) => {
      state.latestError = action.payload;
    },
    openErrorDialog: (state) => {
      state.openErrorDialog = true;
    },
    closeErrorDialog: (state) => {
      state.openErrorDialog = false;
    },
    loadingFinished: (state) => {
      state.loading = false;
    },
    openFormToEdit: (state, action: PayloadAction<OpenFormToEditPayload>) => {
      state.editMode = true;
      state.announcementID = action.payload.id;
      state.announcementBody = action.payload.body;
      action.payload.displayUntilDate
        ? (state.announcementDisplayUntilDate = action.payload.displayUntilDate)
        : (state.announcementDisplayUntilDate = undefined);
      state.announcementPinned = action.payload.pinned;
      state.openFormDialog = true;
    },
    mdEditorChange: (state, action: PayloadAction<string | undefined>) => {
      state.announcementBody = action.payload;
    },
    pinnedChange: (state, action: PayloadAction<ChangeEvent<HTMLInputElement>>) => {
      state.announcementPinned = action.payload.target.checked;
    },
    displayUntilDateChange: (state, action: PayloadAction<string | undefined>) => {
      state.announcementDisplayUntilDate = action.payload;
    },
    postSuccessfulEdit: (state) => {
      state.openFormDialog = false;
      state.openConfirmDialog = false;
      state.announcementBody = DEFAULT_BODY;
      state.announcementDisplayUntilDate = undefined;
      state.announcementPinned = false;
      state.editMode = false;
    },
    postSuccessfulCreate: (state) => {
      state.openConfirmDialog = false;
      state.openFormDialog = false;
      state.announcementBody = DEFAULT_BODY;
      state.announcementDisplayUntilDate = undefined;
      state.announcementPinned = false;
      state.editMode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAnnouncementsFromAPI.fulfilled, (state, action) => {
      state.announcementData = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllAnnouncementsFromAPI.rejected, (state, action) => {
      state.latestError = action.error.message;
      state.loading = false;
      state.openErrorDialog = true;
    });
    builder.addCase(sendNewAnnouncementToAPI.fulfilled, (state, action) => {
      state.openConfirmDialog = false;
      state.openFormDialog = false;
      state.announcementBody = DEFAULT_BODY;
      state.announcementDisplayUntilDate = undefined;
      state.announcementPinned = false;
      state.editMode = false;
      state.announcementData = [...state.announcementData, action.payload];
    });
    builder.addCase(sendNewAnnouncementToAPI.rejected, (state, action) => {
      state.latestError = action.error.message;
      state.openErrorDialog = true;
    });
    builder.addCase(sendUpdatedAnnouncementToAPI.fulfilled, (state, action) => {
      state.openConfirmDialog = false;
      state.openFormDialog = false;
      state.announcementBody = DEFAULT_BODY;
      state.announcementDisplayUntilDate = undefined;
      state.announcementPinned = false;
      state.editMode = false;
      state.announcementData = state.announcementData.map((announcement) =>
        announcement.id === action.payload.id ? action.payload : announcement
      );
    });
    builder.addCase(sendUpdatedAnnouncementToAPI.rejected, (state, action) => {
      state.latestError = action.error.message;
      state.openErrorDialog = true;
    });
  },
});

export const {
  openFormDialog,
  openConfirmDialog,
  openErrorDialog,
  openFormToEdit,
  closeConfirmDialog,
  closeErrorDialog,
  closeFormDialog,
  newError,
  pinnedChange,
  mdEditorChange,
  displayUntilDateChange,
  loadingFinished,
  postSuccessfulCreate,
  postSuccessfulEdit,
} = adminAnnouncementsSlice.actions;

export default adminAnnouncementsSlice.reducer;
