import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => (
  <Box
    display="flex"
    sx={{
      position: 'relative',
      left: '50%',
      top: '50%',
    }}
  >
    <CircularProgress aria-label="User Table Loading" sx={{ display: 'flex' }} />
  </Box>
);

export default Spinner;
