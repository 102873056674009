const ENV_CONFIG_PATH = 'env/config.json';

type Config = {
  amplifyConfig: any;
  adminAPI: string;
  portalURL: string;
};

const envConfig = async (): Promise<Config> => {
  try {
    const res = await fetch(ENV_CONFIG_PATH);
    return (await res.json()) as Config;
  } catch (err) {
    console.error(err);
    throw new Error('There was an error fetching environment configuration');
  }
};

export default envConfig;
