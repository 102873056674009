import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';

interface ModalCardProps {
  title: string;
  bodyText: string[];
  affirmativeText: string;
  open: boolean;
  arialabelledby: string;
  ariadescribe: string;
  cancelCallback(): void;
  affirmativeCallback(): void;
}

const ModalCard = (props: ModalCardProps) => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        open={props.open}
        aria-labelledby={props.arialabelledby}
        aria-describedby={props.ariadescribe}
        PaperProps={{
          style: {
            backgroundColor: '#051c48',
          },
        }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.bodyText.map((name, i) => {
              return (
                <Typography key={i} gutterBottom>
                  {name}
                </Typography>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={props.cancelCallback}>
            Close
          </Button>
          <Button variant="contained" size="small" onClick={props.affirmativeCallback}>
            {props.affirmativeText}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalCard;
