import { Card, CardContent } from '@material-ui/core';
import { Button } from '@mui/material';
import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styles from '../Announcements/Announcements.module.css';
import { useAppDispatch } from '../hooks';
import { openFormToEdit, OpenFormToEditPayload } from './adminAnnouncementsSlice';

type props = {
  id: string;
  body: string;
  pinned: boolean;
  displayUntilDate: string | undefined;
};

const AdminAnnouncement = (props: props) => {
  const dispatch = useAppDispatch();
  const announcement: OpenFormToEditPayload = {
    id: props.id,
    body: props.body,
    displayUntilDate: props.displayUntilDate,
    pinned: props.pinned,
  };
  const styling = props.pinned
    ? {
        card: { margin: '10px', background: 'rgb(217 160 84)', color: 'black' },
        button: { color: 'black', background: 'rgb(217 160 84)', border: '1px solid black' },
      }
    : {
        card: { margin: '10px', background: 'rgb(43 66 135)', color: 'white' },
        button: { color: 'white', background: 'rgb(43 66 135)', border: '1px solid black' },
      };

  return (
    <Card style={styling.card}>
      <CardContent>
        <Button
          data-testid="edit-announcement-button"
          style={styling.button}
          onClick={() => dispatch(openFormToEdit(announcement))}
        >
          Edit
        </Button>
        <div data-testid="admin-announcement">
          <ReactMarkdown className={styles.body}>
            {props.displayUntilDate
              ? `## This announcement will expire in: ${Math.ceil(
                  (Date.parse(props.displayUntilDate) - Date.now()) / (1000 * 3600 * 24)
                )} day(s)\n` + props.body
              : props.body}
          </ReactMarkdown>
        </div>
      </CardContent>
    </Card>
  );
};

export default AdminAnnouncement;
