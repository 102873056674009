import React from 'react';
import { DataGrid, GridColDef, GridSelectionModel, GridCallbackDetails, GridFilterModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DisabledCell from './DisabledCell';
import { useAppSelector, useAppDispatch } from '../hooks';
import Spinner from './Spinner';
import { rowSelection, Row, deptChanged } from './DeptUserAdminSlice';

const UserTables = () => {
  const dispatch = useAppDispatch();

  const generateFilter = (): GridFilterModel | undefined => {
    if (usersDeptOrSupersSelectedDept === '') {
      console.error('Tried to filter with no department selected!');
      return undefined;
    }
    return { items: [{ id: 1, columnField: 'dept', operatorValue: 'equals', value: usersDeptOrSupersSelectedDept }] };
  };

  const rowsSelected = useAppSelector((state) => state.departmentAdmin.rowsSelected);
  const isLoading = useAppSelector((state) => state.departmentAdmin.userIsLoading);
  const rowsData: Row[] = useAppSelector((state) => state.departmentAdmin.rowsData);
  const departments: Array<string> = useAppSelector((state) => state.departmentAdmin.departments);
  const ncscSuper = useAppSelector((state) => state.departmentAdmin.ncscSuper);
  const usersDeptOrSupersSelectedDept: string = useAppSelector(
    (state) => state.departmentAdmin.usersDeptOrSupersSelectedDept
  );

  const columns: GridColDef[] = [
    {
      field: 'dept',
      headerName: 'Dept Name',
      width: 100,
      renderCell: (params) => {
        return <div data-testid="dept-field">{params.row.dept}</div>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 225,
      renderCell: (params) => {
        return <div data-testid="email-field">{params.row.email}</div>;
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      renderCell: (params) => {
        return <div data-testid="firstn-field">{params.row.firstName}</div>;
      },
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      width: 150,
      renderCell: (params) => {
        return <div data-testid="lastn-field">{params.row.surname}</div>;
      },
    },
    {
      field: 'disabledReason',
      headerName: 'Account Status',
      width: 250,
      renderCell: (params) => {
        return (
          <DisabledCell
            disabled={params.row.disabled}
            disabledReason={params.row.disabledReason}
            lastSuccessfulLogin={params.row.lastSuccessfulLogin}
          />
        );
      },
    },
    {
      field: 'admin',
      headerName: 'Admin',
      width: 75,
      renderCell: (params) => {
        return params.row.admin ? (
          <span title="Admin is enabled">
            <CheckCircleRoundedIcon
              data-testid="admin-enabled-icon"
              aria-label="Admin enabled"
              sx={{ color: 'rgb(76, 175, 80)' }}
            />
          </span>
        ) : (
          <span title="Admin is disabled">
            <CancelRoundedIcon
              data-testid="admin-disabled-icon"
              aria-label="Admin disabled"
              sx={{ color: 'rgb(244, 67, 54)' }}
            />
          </span>
        );
      },
    },
    {
      field: 'receiveCARNotification',
      headerName: 'Receive CAR Notifications',
      width: 200,
      renderCell: (params) => {
        return params.row.receiveCARNotification ? (
          <span title="User will receive CAR notifications">
            <CheckCircleRoundedIcon
              data-testid="CAR-enabled-icon"
              aria-label="CAR notifications enabled"
              sx={{ color: 'rgb(76, 175, 80)' }}
            />
          </span>
        ) : (
          <span title="User will not receive CAR notifications">
            <CancelRoundedIcon
              data-testid="CAR-disabled-icon"
              aria-label="CAR notifications disabled"
              sx={{ color: 'rgb(244, 67, 54)' }}
            />
          </span>
        );
      },
    },
    {
      field: 'lastSuccessfulLogin',
      headerName: 'Last Login',
      width: 175,
      renderCell: (params) => {
        if (params.row.lastSuccessfulLogin && params.row.lastSuccessfulLogin.length > 19) {
          return (
            <div data-testid="last-login-field">{params.row.lastSuccessfulLogin.slice(0, 19).replace('T', ' ')}</div>
          );
        } else {
          return '';
        }
      },
    },
  ];

  return (
    <span>
      {ncscSuper ? (
        <Box sx={{ width: 150 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="Dept">Department</InputLabel>
            <Select
              labelId="department-selector"
              id="select-dept"
              value={usersDeptOrSupersSelectedDept}
              label="Department"
              placeholder="Department"
              onChange={(e) => dispatch(deptChanged(e.target.value))}
            >
              {departments.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : null}
      <Box style={{ width: '100%', height: 400 }}>
        {/*Using Spinner insead of the default as it doesn't comply with Aria requirments*/}
        <DataGrid
          components={{
            LoadingOverlay: Spinner,
          }}
          loading={isLoading}
          filterModel={ncscSuper ? generateFilter() : undefined}
          disableColumnFilter
          columns={columns}
          rows={rowsData}
          columnBuffer={8}
          checkboxSelection={true}
          selectionModel={rowsSelected}
          onSelectionModelChange={(model: GridSelectionModel, details: GridCallbackDetails) =>
            dispatch(rowSelection(model as number[]))
          }
          disableVirtualization={true}
        />
      </Box>
    </span>
  );
};

export default UserTables;
